body{
  /*background-color: #212529;*/
}
.img__table {
  width: 50px;
}

.btn__add {
  background-color: #00cc00 !important;
}

.img__table {
  width: 50px;
}

.nav-tabs .nav-link {
  padding: 10px !important;
}


.btn-transparent {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: large;
  font-family: "Merriweather", serif;
}
.btn-transparent:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: #86d4f5;
}

@media (max-width: 576px) {
  .btn-transparent {
    font-size: x-small;
  }
}

@media (max-width: 967px) {
  .btn-transparent {
    font-size: small;
  }
}
.recipe--title{
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .recipe--title{
    margin-top: 20px;
  }
}

.ingredient--title{
  margin-left: 2rem;
}

.new__recipe__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.new__recipe__image__file {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.capitalize-title {
  text-transform: capitalize;
}

.card-custom {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.left-list-group {
  border: none !important;

}

.super-search-input{
  min-width: 250px;
}

.sidebar {
  /*min-width: 175px;*/
  position: sticky;
  top: 0;
  left: 0;
  height: 100%;
  /*overflow-y: auto;*/

}

@media (max-width: 768px) {
  .sidebar {
    display: none;

  }
  h2{
    margin-top: 10px;
  }
}

.jaime-btn{
  float: left;
  margin-left: 50px;
}

.heart-container-recipe{
  position: absolute;
  margin-left: 50px;
}

@media (max-width: 768px) {
  .heart-container-recipe{
    margin-left: 10px;
  }
}

.heart{
  max-width: 75px;
  cursor: pointer;
}


/* Styles for centering the pagination */
.pagination-container {
  display: grid;
  justify-content: center;
  margin-top: 20px; /* You can adjust the margin as needed */
}

@media (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
}


/* Styles pour les écrans mobiles (<= 768px) */
@media (max-width: 768px) {

  .navbar-nav .nav-item {
    margin-bottom: 10px; /* Ajoutez de l'espace entre les éléments de menu */
  }
  .navbar-nav{
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* Supprimez la marge des boutons et faites-les occuper toute la largeur du menu */
  .navbar-nav .nav-item {
    width: 100%;
    text-align: center; /* Centrez le texte à l'intérieur des boutons */
  }

  /* Facultatif : Ajustez les styles des boutons si nécessaire */
  .navbar-nav .btn {
    padding: 10px; /* Ajustez la taille des boutons */
    border-radius: 5px; /* Ajoutez des coins arrondis */
  }


}


.add-btn-container {
  position: fixed; /* Position fixe par rapport à la fenêtre du navigateur */
  bottom: 20px; /* Espacement depuis le bas */
  right: 40px; /* Espacement depuis la droite */
  z-index: 9999; /* Indice de profondeur élevé pour être au-dessus de tous les autres éléments */
  color: #fff; /* Couleur du texte */
  width: 75px; /* Largeur du conteneur */
  height: 75px; /* Hauteur du conteneur */
  display: flex; /* Affiche les éléments à l'intérieur en ligne */
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  border-radius: 50%; /* Transforme le conteneur en cercle */
  cursor: pointer; /* Curseur pointeur au survol */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Ombre légère pour le conteneur */
}

/* Style pour l'icône à l'intérieur du conteneur */
.add-btn-container i {
  font-size: 50px; /* Taille de l'icône */
}
.add-btn-container i{
  color: white;
}

.dropdown-item{
  text-align: center;
}

.btn-pas-inscrit{
  text-align: right;
}

.pas-inscrit-container{
  display: inline-block;
  margin-top: 40px;
  float: right;
}
.f-logo{
  width: 20px;
  height: auto;
  margin-left: 10px;
  margin-right: 30px;
  transform: rotate(45deg);
}
.f-logo:hover{
  transform: rotate(0deg);
}

.f-logo-footer{
  width: 10%;
  height: auto;
  margin-right: 50px;
  transform: rotate(90deg);
}
.f-logo-footer-inverse{
  width: 10%;
  height: auto;
  margin-left: 50px;
  transform: rotate(270deg);
}
audio{
  display: none;
}




.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 100%);
  overflow: hidden;
  scroll-behavior: smooth;
}
.wrapper section {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin: 20px 0;
}
.wrapper section .item {
  padding: 0 2px;
  transition: 250ms all;
}
.wrapper section .item:hover {
  margin: 0 40px;
  transform: scale(1.2);
}
.wrapper section a {
  position: absolute;
  color: #fff;
  text-decoration: none;
  font-size: 6em;
  background: black;
  width: 80px;
  padding: 20px;
  text-align: center;
  z-index: 1;
}
.wrapper section a:nth-of-type(1) {
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, black 100%);
}
.wrapper section a:nth-of-type(2) {
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 100%);
}
@media only screen and (max-width: 600px) {
  a.arrow__btn {
    display: none;
  }
}

.utilisation-texte{
  float: right;
}



.super-btn {
  text-decoration: none;
  background-color: red;
  color: #fff;
  padding: 30px 50px;
  border-radius: 10% 90% 70% 30% / 30% 56% 44% 70%;
  border: none;
  cursor: pointer;
  font-size: 35px;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  box-shadow: 0 6px darkred;
}

.super-btn:hover {
  box-shadow: 0 4px darkred;
  top: 2px;
}

.super-btn:active {
  box-shadow: 0 0 darkred;
  top: 6px;
}

.container-card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

.container-card-all {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .container-card-all {
    width: 90%;
  }
}
.cercle {

  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: royalblue;
  background: url("https://images.unsplash.com/photo-1614027164847-1b28cfe1df60?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bGlvbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60");
  background-size: cover;
  margin: 25px 25px 25px 0px;

  shape-outside: circle();
}
.c1{
  float: left;
}
.c2{
  float: right;
}
.cercle-image img{
  width: 500px;
  height: 500px;
  border-radius: 50%;
  margin: 30px 30px 30px 30px;
  float: right;
  shape-outside: circle();
}

.big-circle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  opacity: 0.5;
  height: 80%;
}
.medium-circle {
  position: absolute;
  top: 30%;
  right: 30%;
  z-index: -1;
  height: 60%;
  opacity: 0.4;
}
.small-circle {
  position: absolute;
  bottom: -20%;
  left: 20%;
  z-index: -1;
}

@media (max-width: 767px) {
  .big-circle {
    display: none;
  }
  .medium-circle {
    display: none;
  }
  .small-circle {
    display: none;
  }
}


/* Styles pour les écrans de petite taille (mobiles) */
@media (max-width: 998px) {
  .navbar-nav .btn {
    width: 100%; /* Rendre les boutons plein largeur */
    margin-bottom: 5px; /* Ajouter de l'espace entre les boutons */
  }
  .badge-category.badge{
    display: none;
  }
}


/* Styles pour les écrans de petite taille (mobiles) */
@media (max-width: 1200px) {
  .list-group .badge.badge-category{
    display: none;
  }
}

.btn-close{
  font-size: 50px;
}

.navbar{
  /*background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));*/
}
.container-navbar{
  position: absolute;
  bottom: 400px;
  /*position: absolute;*/
}

.max-width-table {
  max-width: 200px; /* Remplacez 800px par la largeur maximale souhaitée */
  /* Vous pouvez également ajouter d'autres styles si nécessaire */
}

.btn-like-article{
  position: relative;
  margin-right: 30px;
  margin-top: 30px;
}
.article-title{
  margin-top:-30px;
}


.left-offset {
  padding-top: 50px;
  margin-left: -20%; /* Réglez la valeur de marge gauche selon vos besoins */
  padding-bottom: 50px;
}
.block-picture-left{
  padding-top: 100px;
  margin-left: 100px;
}

.special-card{
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));

}
.fullscreen-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.picture-right{
  transform: rotate(10deg);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}
.description{
  margin-top: 20px;
}

.picture-right img{
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .picture-right{
    margin-left: 20px;
  }
}

@media (max-width: 998px) {
  .picture-right{
    margin-left: 20px;
  }
}
.picture-left-title{
  transform: rotate(-10deg);
}

.picture-left{
  transform: rotate(-10deg);
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.4);
}

@media (max-width: 767px) {
  .picture-left{

  }
}

/* Cacher les flèches du spinner */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
#recipe-ingredient td {
  vertical-align: middle;
  text-align: center;
}

.super-logo{
  margin-top: 30px;
  margin-right: 30px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 80%;
  /*float: right;*/
  animation: rotate 5s linear infinite;
}

.super-logo:hover {
  animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-thanks{
  font-size: 4px;
}
.col-album-2{
  border-radius: 87% 13% 88% 12% / 22% 73% 27% 78% ;
  border: 1px solid black;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.col-picture-4{
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.4);
}

.block {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.block::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: black;
  top: 0;
  left: 0;
  animation: drawLine 3s linear infinite;
  transform-origin: left;
}

@keyframes drawLine {
  0% {
    width: 0;
  }
  50% {
    width: 50%; /* Dessine la ligne jusqu'à la moitié de la largeur du bloc */
    left: 50%;
    rotate: 45deg;
  }
  100% {
    width: 50%; /* Dessine la ligne jusqu'à la largeur complète du bloc */
    left: 50%;
    rotate: 45deg;

  }
}

.logo-super-banner{
  padding: 20px;
  background-color: lightblue;
  position: relative;
  rotate: 20deg;
  left: 0;

  top: 200px;
  float: right;
}

.logo-super-banner img{
  max-width: 100px;;
}

.f-banner-logo{
  height: 100px;
  width: auto;
}

.block-super{
  max-width: 100%;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.4);
}

.block-super-left{
  max-width: 100%;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.4);
}


@media (max-width: 768px) {
  .block {
    height: auto;
  }

  .block::before {
    display: none;
  }

  .logo-super-banner {
    display: none;
  }

  .block-super {
    display: none;
  }

}

@media (max-width: 998px) {
  .block {
    height: auto;
  }

  .block::before {
    display: none;
  }

  .logo-super-banner {
    display: none;
  }

  .block-super {
    display: none;
  }

  .block-super-left{
    display: none;
  }

}


@media (max-width: 480px) {
  .bottom-nav{
    position: static;
    left: 0;
  }

}

@media (max-width: 580px) {
  .bottom-nav{
    position: relative;
    float: right;
    left: 35%;
  }

}

.drag-drop-container {
  text-align: center;
  font-family: monospace;
}

.drag-drop-board {
  display: flex;
  justify-content: space-around;
}

.drag-drop-header {
  text-decoration-line: underline;
  font-size: 1.5rem;
}

.task-header {
  margin: 10px;
  text-decoration: underline;
  font-size: 1rem;
}

.task-card {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 5px;
}

.container-card-user {
  position: relative;
  width: 100%;
  height: 200px;
  z-index: 0;
}

.img-cadre {
  position: absolute;
  max-width: 140%;
  min-height:90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.img-user {
  max-width: 100px;
  min-height: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* styles.css */
.move-animation {
  animation: moveLeftRight 2s linear infinite alternate;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(500%); /* Ajustez la distance de déplacement comme souhaité */
  }
}

.col-special-radius{
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  border-radius: 87% 13% 88% 12% / 22% 73% 27% 78% ;

}

@media (max-width: 768px) {
  .col-special-radius{
    border-radius: 25px 25px 55px 5px/5px 55px 25px 25px
  }
}

.super-background-quebec {
  width: 100%;
  height: auto;
  /*background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url("https://cdn.pixabay.com/photo/2017/03/10/06/15/quebec-2131892_960_720.jpg");*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*border-bottom: 1px dotted #777;*/
  padding-top: 1px;
}
.row-subscribe{
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: lightblue;
  width: 100%;
  height: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("./assets/img/pano/pano1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.row-contact-form{
  width: 100%;
  height: auto;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("./assets/img/food1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-contact-form input:focus{
  border: white 1px solid;
  color: white;
  background-color: transparent;
}
.back-transparent{
  border: white 1px solid;
  color: white;
  background-color: transparent;
}
.home-contact-form label{
  color: white;
}
.super-alert{
  position: absolute;
  top: 170%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 999;

}

.input-subscribe{
  border: white 1px solid;
  color: white;
  background-color: transparent;
}
.label-subscribe{
  color: white;
}

.input-subscribe:focus{
  border: white 1px solid;
  color: white;
  background-color: transparent;
}
.carousel-item{
  max-height: 300px;
}
.container-spacer-20{
  height: 20px;
  width: 100%;
}

.container-spacer-100{
  height: 100px;
  width: 100%;
}

.container-spacer-75{
  height: 75px;
  width: 100%;
}

.container-spacer-50{
  height: 50px;
  width: 100%;
}

/************************Template **********************/
/***overview***/
.overview-wrap .title-overview{
  text-align: center;
  color: #3dce8c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 23px;
}
.overview-wrap .subtitle-overview{
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  max-width: 660px;
  margin: 19px auto;
  text-align: center;
}






/***skewed***/
.sesgoabajo{
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  border-width: 0 0 35vh 100vw;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}
.sesgoarriba{
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  border-width: 35vh 100vw 0 0;
  border-style: solid;
  border-color: #ffffff transparent transparent  transparent;
}
.contenedor{
  overflow: hidden;
  padding: 100px 5px;
}




/***our team***/
.our-team{position: relative;padding: 5rem 0;}
.title-our-team{
  text-align: center;
  color: #3dce8c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 23px;
}
.subtitle-our-team{
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  max-width: 660px;
  margin: 19px auto 103px;
  text-align: center;
}
.subtitle-our-lastest-post{
  line-height: 40px !important;
  font-size: 25px !important;
  padding-right: 60px;
  padding-left: 60px;
}



/***overview***/
.overview-box{
  height: 240px;
  width: 100%;
  text-align: center;
  padding: 35px 20px;
}
.overview-box:hover{box-shadow: 0px 0px 10px rgba(0,0,0,0.3);transition: all ease 1s;}
.overview-box h5{
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
  text-transform: uppercase;
  color: #606060;
  margin-bottom:20px;
}
.overview-box p{font-size: 14px;color:#666666;}
.features-icons-icon {
  vertical-align: middle;
  margin: 20px auto;
}
.features-icons-icon i {color:lightgrey;}
.overview-box:hover .features-icons-icon i.html5 {
  color: #e54d26;
  transition: all ease 1s;
}.overview-box:hover .features-icons-icon i.css3 {
   color: #379ad5;
   transition: all ease 1s;
 }.overview-box:hover .features-icons-icon i.android {
    color: #a4c739;
    transition: all ease 1s;
  }
.overview-box:hover .features-icons-icon i.drupal {
  color: #0c76ab;
  transition: all ease 1s;
}




/***gallery***/

.galeria{
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  position: relative;
}
.imagenes{
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.imagenes img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.enmica{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 73, 94, 0.815);
}
.enmica h2{
  position: relative;
  top: 45%;
  text-align: center;
  color: #ffffff;
}
.enmica div{
  position: relative;
  display: block;
  top: 46%;
  width: 40px;
  height: 5px;
  background: #ffffff;
  margin: auto;
}




/***testimonial***/
.testimonials-wrap{
  background: linear-gradient(0deg, #08d4fd 0%, #503eff 100%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  padding: 8rem 0 12rem;
}
.title-testimonials{
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 23px;
}
.subtitle-testimonials{
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  color: #ffffff;
  max-width: 660px;
  margin: 19px auto 103px;
  text-align: center;
}
.slide-one {
  padding: 30px 70px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #00000059;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  text-align: center;
}
.banner-slide img {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}
.banner-slide h5 {
  color: #046ab3;
  font-weight: 700;
  margin: 15px 0;
}
.banner-slide h6 {
  font-size: 15px;
  color: #000;
  margin-bottom: 45px;
  font-weight: 600;
}
.banner-slide p {
  color: #000;
  font-size: 16px;

}
.carousel-control-prev {
  left: -175px;
}
.carousel-control-next {
  right: -175px;
}

.carousel-indicators{bottom:-70px;}




/******/
.row.heading h2 {
  color: #fff;
  font-size: 52.52px;
  line-height: 95px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 40px;
  padding-bottom: 20px;
  text-transform: uppercase;
}
ul{
  margin:0;
  padding:0;
  list-style:none;
}

.our-team .mycard-block{
  float:left;
  width:100%;
  background:#fff;
  padding:30px 20px;
  text-align:center;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  border: 3px solid #ffffff;
  margin-bottom: 2rem;
}
.our-team .mycard-block:hover{
  border: 3px solid #503eff;
}

.our-team .mycard-block img{
  width:130px;
  height:130px;
  border-radius:100%;
  object-fit: cover;
}
.our-team .mycard-block h3{
  color:#2a2a2a;
  font-size:20px;
  font-weight:500;
  padding:6px 0;
  text-transform:uppercase;
}
.our-team .mycard-block h3 a{
  text-decoration:none;
  color: #606060;
  font-weight: 700;
}
.our-team .mycard-block h3 a:hover{
  color:#337ab7;
}
.our-team .mycard-block p{
  color:#2a2a2a;
  font-size:13px;
  line-height:20px;
  font-weight:400;
}

.our-team .mycard-block .follow-us li{
  display:inline-block;
  width:auto;
  margin:0 5px;
}

.btn-circle {
  border-radius: 50%;
}
.my-social-btn {
  background: lightgrey;
}
a.btn.btn-circle.my-social-btn {
  color: #fff;
  height: 40px;
  width: 40px;
}
.mycard-block:hover .my-social-btn.fb {
  background: #205b9f;
}
.mycard-block:hover .my-social-btn.twitter {
  background: #00ace3;
}
.mycard-block:hover .my-social-btn.google {
  background: #ff3921;
}



.testimonials{padding: 18rem 0;}


@media only screen and (max-width:736px) and (orientation:landscape){}
@media only screen and (max-width:667px) and (orientation:landscape){}
@media only screen and (max-width:568px) and (orientation:landscape){}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .enmica h2{font-size:18px;}
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  header .tophead {margin-top: 145px;}
  header .title-main {font-size: 28px;}
  header .subtitle-main {font-size: 13px;}
  header .btn-explore{padding: 5px 8px;font-size: 13px;}
  header .btn-account{padding: 5px 8px;font-size: 13px;}
  /******/
  .overview .title-overview{font-size: 28px;}
  .overview .subtitle-overview{font-size: 14px;}
  /******/
  .title-our-team{font-size: 28px;}
  .subtitle-our-team{font-size: 14px;}
  /******/
  .title-testimonials{font-size: 28px;}
  .subtitle-testimonials{font-size: 14px;}
  /******/
  .slide-one {padding: 30px 25px;}
  /******/
  section#blog .title-blog{font-size: 28px;}
  section#blog .subtitle-blog{font-size: 14px;}
  /******/
  .contactForm{padding: 0;}
  .contact-wrap h2{font-size: 28px;}
}

@media only screen and (min-width: 321px) and (max-width: 479px) {
  header .tophead {margin-top: 145px;}
  header .title-main {font-size: 25px;}
  header .subtitle-main {font-size: 13px;}
  header .btn-explore{padding: 5px 8px;font-size: 13px;}
  header .btn-account{padding: 5px 8px;font-size: 13px;}
  /******/
  .overview .title-overview{font-size: 25px;}
  .overview .subtitle-overview{font-size: 14px;}
  /******/
  .title-our-team{font-size: 25px;}
  .subtitle-our-team{font-size: 14px;}
  /******/
  .title-testimonials{font-size: 25px;}
  .subtitle-testimonials{font-size: 14px;}
  /******/
  .slide-one {padding: 30px 25px;}
  /******/
  section#blog .title-blog{font-size: 25px;}
  section#blog .subtitle-blog{font-size: 14px;}
  /******/
  .contactForm{padding: 0;}
  .contact-wrap h2{font-size: 25px;}
}


@media only screen and (max-width: 320px) {
  header .tophead {margin-top: 125px;}
  header .title-main {font-size: 18px;}
  header .subtitle-main {font-size: 13px;}
  header .btn-explore{padding: 5px 8px;font-size: 13px;}
  header .btn-account{padding: 5px 8px;font-size: 13px;}
  /******/
  .overview .title-overview{font-size: 25px;}
  .overview .subtitle-overview{font-size: 14px;}
  /******/
  .title-our-team{font-size: 25px;}
  .subtitle-our-team{font-size: 14px;}
  /******/
  .title-testimonials{font-size: 25px;}
  .subtitle-testimonials{font-size: 14px;}
  /******/
  .slide-one {padding: 30px 25px;}
  /******/
  section#blog .title-blog{font-size: 25px;}
  section#blog .subtitle-blog{font-size: 14px;}
  /******/
  .contactForm{padding: 0;}
  .contact-wrap h2{font-size: 25px;}

}

/*********** Heart ***********/
.count-like{
  position: relative;
  top: -24px;
  left: 0;
}

.heart[animation="0"] {
  animation: pulsate-bck 0.8s ease-in-out infinite both;
}

.heart[animation="1"] {
  animation: jello-horizontal 0.9s both;
}

.notifications-container {
  font-size: 15px;
  height: 40px;
  width: 40px;
  position: fixed;
  top: 15px;
  right: 10px;
}

.notifications-container[animation="1"] {
  animation: heartbeat 1.5s ease-in-out both;
}

.heart-icon {
  max-width: 100%;
  max-height: 100%;
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.9);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}


.f-logo-sidebar-Project{
  position: relative;
  margin-left: 40%;
  max-height: 50px;
  transform: rotate(40deg);
}

.navbar-toggler{
  background-color: white !important;
}


/* ===========================================================
Main
=========================================================== */

.banner {
  background-color: #323232;
  color: #f2f2f2;
  padding-top: 7.2rem;
  text-transform: uppercase;
  text-shadow: 0 0 0.5rem #008080;
  font-family: sans-serif;
  animation-name: flashing;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 5s;
}

.banner h1 {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.banner h4 {
  margin: 0;
}

.parallax1 {
  background: url(assets/images/ben-kolde-bs2Ba7t69mM-unsplash.jpg);
  min-height: 100%;
}

.parallax2 {
  background: url(assets/images/building-102840_1280.jpg);
  min-height: 60%;
}

.parallax3 {
  background: url(assets/images/plans-1867745_1280.jpg);
  min-height: 40%;
}

.parallax4 {
  background: url(assets/images/marvin-meyer-SYTO3xs06fU-unsplash.jpg);
  min-height: 100%;
}

.img-home{
  max-width: 100%;
}

.parallax1,
.parallax2,
.parallax3,
.parallax4 {
  position: relative;
  opacity: 0.75;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 30rem;
}

.heading,
.heading-sm {
  position: absolute;
  color: #ffffff;
  width: 100%;
  text-transform: uppercase;
  text-shadow: 0 0 10px #000;
}

.heading {
  font-size: 2rem;
  top: 18%;
  letter-spacing: 8px;
}

.two-col p,
.two-col video,
.dark p,
form {
  font-size: 0.7rem;
  text-align: justify;
  line-height: 1.3rem;
  margin: 1rem 0 0 0;
  width: 100%;
}

.heading-sm {
  text-align: center;
  top: 45%;
  color: #ffffff;
  font-size: 3.5rem;
  letter-spacing: 5px;
}

.dark {
  overflow: auto;
  background-color: #323232;
  color: #f2f2f2;
}

.img-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.product-img{
  max-width: 200px;
  margin: 20px;
}

.product-img img {
  margin: 0 0.5rem;
  transition: all 1s;
}

.product-img p,
.form-container p {
  margin: 0 0 0.5rem 0;
  text-align: center;
}

.form-container {
  margin: auto;
  max-width: 500px;
  padding: 0;
}
.construire-container {
  padding:1rem 2rem;
}

.form-box input,
.form-box textarea {
  margin-bottom: 1rem;
  display: inline-block;
  width: 100%;
  border-radius: 0.25rem;
  resize: none;
  padding: 0.5rem 0 0.5rem 0.2rem;
  margin-bottom: 0.3rem;
  font-family: "Courier New", Courier, monospace;
}

.form-box textarea {
  padding: 0.5rem 0 3rem 0.2rem;
}

.product-img img:hover {
  opacity: 0.5;
  transform: scale(1.1);
}

.home-container section {
  overflow: auto;
  padding: 1rem 2rem;
  margin-bottom: 0;
}

video {
  width: 100%;
}

/*.home-container button {*/
/*  font-weight: 700;*/
/*  border-radius: 0.5rem;*/
/*  text-transform: uppercase;*/
/*  background-color: #008080;*/
/*  border-color: #008080;*/
/*  border-width: 0.2rem;*/
/*}*/

/*.home-container button:hover {*/
/*  background-color: #000;*/
/*  border-color: #000;*/
/*  color: #ffffff;*/
/*  border-width: 0.2rem;*/
/*}*/

/*.home-container input[type="submit"] {*/
/*  width: 100%;*/
/*  background-color: #fff;*/
/*  color: #000000;*/
/*  padding: 1rem;*/
/*  margin-bottom: 0.3rem;*/
/*  border: none;*/
/*  border-radius: 0.2rem;*/
/*  cursor: pointer;*/
/*}*/
.page-link.active, .active > .page-link {
  background-color: grey !important;
}
/* ===========================================================
Media Query
=========================================================== */

@media (min-width: 768px) {
  .parallax1,
  .parallax2,
  .parallax3,
  .parallax4 {
    padding-top: 50rem;
  }

  .heading {
    margin-left: 50px;
    font-size: 6rem;
    top: 8%;
  }


  .two-col {
    float: left;
    margin-left: 5%;
    width: 42%;
    padding: 0 4%;
    text-align: left;
  }

  .two-col p {
    font-size: 100%;
    line-height: 1.5rem;
  }

  .dark {
    overflow: auto;
    padding: 2.5rem 4rem;
    background-color: #323232;
    color: #f2f2f2;
  }

  .home-container section {
    padding: 1rem;
  }
}
/* ===========================================================
Keyframes
=========================================================== */

@keyframes flashing {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


.fixed-sidebar {
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  height: auto;
  width: 250px; /* Set the desired width of the sidebar */
  padding: 20px;
}



@media screen and (max-width: 1200px){
  .fixed-sidebar.container-side-bar{
    display: none;
  }
}